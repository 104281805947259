import React from "react";
import Header from "./Header";
import Status from "./Status";

export default function Journey({
trackingDetails
}) {
    // console.log('dsds', trackingDetails)
  const {  status = "",
  comment = "",
  date = "",
  formattedDate = "",
  time = "",
  hideUpdatedOn=false}=trackingDetails;
  return (
    <div className="border">
      <Header date={date} hideUpdatedOn={hideUpdatedOn} />

      <Status time={time} status={status} comment={comment} formattedDate={formattedDate} />
    </div>
  );
}
