import {API_ENDPOINT_ALL_TRACKERS, API_ENDPOINT_SEARCH} from "../../services/apiEndpoints";
import { fetchData } from "./apiRequest";
export const fetchAllTrackingNumber=(query) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("GET", API_ENDPOINT_ALL_TRACKERS,query))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  };


  export const search=(query) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("GET", `trackers/search/${query}`,''))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  };
  
  export const searchTrackingNumber = (trackNumber) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("GET", "unknown",trackNumber))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  };
  
  export const searchAdminTrackingNumber = (trackNumber) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("GET", "unknown",trackNumber))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  };
  export const changePage = (page) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("POST", "unknown",page))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  };
  
  export const changeRowPerPage = (page) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("POST", "unknown",page))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  };
  
  

  

    
  export const filterChange = (filterBy) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("POST", "unknown",filterBy))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  };