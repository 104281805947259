import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../assets/style/common.scss";

export default function SearchTracking({ showError, setFilter }) {
    const url = useLocation();
    const urlhistory = useHistory();
    const [trackingNumber, setTrackingNumber] = useState("");
    const onSearch = (e) => {
        e.preventDefault();
        if (trackingNumber) {
            urlhistory.push(`/home?tracking=${trackingNumber}`);
            // setTrackingNumber("");
        } else {
            urlhistory.push("/home")
        }
    };

    return (

        <div className="container-content align-items-center d-flex ">

            <div className="col-lg-12 col-sm-12">
                <div className="mt-3 text-left mb-4">
                    <span className="search-subTitle ">
                        Enter Your <b>Tracking Number</b>
                    </span>
                </div>
                <div className="row mb-5">
                    <form className="col-lg-7 col-sm-12" onSubmit={onSearch}>
                        <div className="search-tracking-div  input-group ">
                            <input
                                type="text"
                                id="search"
                                className="searchText-tracking ml-3  form-control no-outline"
                                placeholder="Tracking Number"
                                title="Enter Tracking Number"
                                onChange={(e) => setTrackingNumber(e.target.value.trim())}
                                value={trackingNumber}
                                title="Enter only number"
                            ></input>
                            <div className="input-group-append">
                                <span className="search-icon pl-5 ml-4" onClick={onSearch}></span>
                            </div>
                        </div>
                        {showError ? (
                            <div className="error text-left w-100 mb-2">Tracking Number Not Found</div>
                        ) : (
                                ""
                            )}
                    </form>
                    {/* <div className="col-lg-5 col-sm-12 d-flex align-items-center">
                        <div className="checkbox-size">
                            <input type="checkbox" onChange={(e) => { setFilter(e.target.checked) }}></input>
                        </div>
                        <label className="pt-1 pl-2">Delivered/Archived only</label>

                    </div> */}
                </div>

            </div>
           

        </div>
    )
}
