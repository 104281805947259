/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Login from "../components/Login/login";
//import logo from "../assets/images/logo.png";
import logo from '../assets/images/nli-logo.svg'

export default function (props) {
  return (
    <div className="container">
      <div className="d-flex justify-content-center pt-5  ">
        <div className="col-xs-8 col-sm-8 col-lg-12">
          <div className="align-self-center">
            <img
              className="justify-content-center pb-1 mb-5"
              src={logo}
              height="auto"
              width="auto"
              alt="Card image cap"
            />

            {/* <div className="row justify-content-center login-text">
              <p className="col-lg-8 col-md-10 col-12">Lorem Ipsum is simply typesetting industry dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's simply typesetting industry.</p>
            </div> */}
            <Login redirectLocation={props.location.state && props.location.state.from && props.location.state.from.pathname} />

          </div>
        </div>
      </div>
    </div>

  );
}
