/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Switch, Route, Redirect } from "react-router-dom";

import ResetPasswordPage from "./pages/ResetPasswordPage";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";


import AdminHomePage from "./pages/AdminHomePage";
import { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { getUserSession } from './redux/actions/userActions'

import RootPage from "./pages/RootPage";
import TrackHome from "./pages/search-frontend/TrackHome";

function App(props) {
  const { user } = props

  useEffect(() => {
    props.getUserSession()
  }, [])

  const [authentication] = useState({
    authenticated: false,
    loading: true,
  });
  const { authenticated, loading } = authentication;

  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page

      <Route
        {...rest}
        render={(props) => {
          return user && user.user && user.user.token ? <Component {...props} /> : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        }}
      />
    );
  };
  return (
    <div className="App">
    
      {/* {user && user.user && user.user.token && <Header />} */}
      <Switch>
        <Route exact path="/search" component={TrackHome} />
        <Route exact path="/login" component={LoginPage} />
        {/* <Route exact path="/register" component={RegisterPage} /> */}
        <Route exact path="/forgot" component={ForgotPasswordPage} />
        <Route exact path="/reset" component={ResetPasswordPage} />
        <PrivateRoute exact path="/home" component={AdminHomePage} />
        {/* <PrivateRoute exact path="/add-new" component={AddNewPage} />
        <PrivateRoute exact path="/edit" component={EditPage} /> */}
        <Route exact path="" component={RootPage}/>

      </Switch>
    </div>
  );
}

const mapStateToProps = storeState => {
  return {
    user: storeState.user
  }
}
export default connect(mapStateToProps, { getUserSession })(App)
