import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../assets/style/common.scss";

export default function SearchText({ showError, trackDetails, showOldLink = false }) {
  const url = useLocation();
  //   var currentUrl = url.pathname.slice(1);
  const urlhistory = useHistory();
  const [trackingNumber, setTrackingNumber] = useState("");

  const onSearch = (e) => {
    e.preventDefault();
    if (trackingNumber) {
      urlhistory.push(`/search?tracking=${trackingNumber}`);
      setTrackingNumber("");
    }
  };
  return (
    <div className="container-content align-self-center">
      {trackDetails && trackDetails.length ? (
        <React.Fragment>
          <div className="mt-3 text-left mb-4">
            <span className="search-subTitle ">
              Enter Your <strong>Tracking Number</strong>
            </span>
          </div>
          <form onSubmit={onSearch}>
            <div className="search-tracking-div  input-group mb-5">
              <input
                type="text"
                id="search"
                className="searchText-tracking ml-3  form-control no-outline"
                placeholder="Tracking Number"
                title="Enter Tracking Number"
                onChange={(e) => setTrackingNumber(e.target.value)}
                value={trackingNumber}
                title="Enter only number"
              ></input>
              <div className="input-group-append">
                <span
                  className="search-icon"
                  onClick={onSearch}
                ></span>
              </div>
            </div>
            {showError ? (
              <div className="error text-left w-100 mb-2">
                Tracking Number Not Found
              </div>
            ) : (
                ""
              )}
          </form>
        </React.Fragment>
      ) : (
          <React.Fragment>
            <form onSubmit={onSearch}>
              <div className="row justify-content-center align-items-center ">
                <div className="col-lg-12 mt-5">
                  <span className="search-subTitle">
                    Enter your <strong>Tracking Number</strong>
                  </span>
                </div>
                <div className="w-100"></div>

                <div className="col-lg-6 col-md-12 col-sm-12 mt-4 ">
                  <div className="input-group search-div mb-3">
                    <input
                      type="text"
                      id="search"
                      placeholder="Tracking Number"
                      title="Enter Tracking Number"
                      onChange={(e) => setTrackingNumber(e.target.value)}
                      value={trackingNumber}
                      title="Enter only number"
                      className="form-control ml-3 searchText"
                    ></input>
                    <div className="input-group-append mt-2">
                      <span className="search-icon" onClick={onSearch}></span>
                    </div>
                  </div>
                  {showError ? (
                    <div className="error text-left pr-5 mb-2 ">
                      Tracking Number Not Found
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              </div>
            </form>
          </React.Fragment>
        )}
      {showOldLink && <div className="row justify-content-center login-text">
        {/* <p className="col-lg-8 col-md-10 col-12">For orders placed before Dec 31 2020, please track your oder here: <a style={{ cursor: "pointer" }} href="http://nliinternational.com/tracking/search.php">http://nliinternational.com/tracking/search.php</a></p> */}
      </div>
      }
    </div>
  );
}
