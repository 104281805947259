import queryString from 'query-string';
export const API_ENDPOINT_LOGIN = 'users/login';
export const API_ENDPOINT_REGISTER = 'users/register';
export const API_ENDPOINT_USERS = 'users';
export const API_ENDPOINT_LOGOUT='logout';
export const API_ENDPOINT_ADD_TRACKER="trackers"
export const API_ENDPOINT_ALL_TRACKERS="trackers"


export const API_ENDPOINT_FORGOTPASSWORD = 'forgot-password';
export const API_ENDPOINT_SEARCH="trackers/search/"
export const API_ENDPOINT_SILENT_LOGIN = 'users/refresh';
export const API_ENDPOINT_AUTH_LOGOUT = 'users/logout';

// export const itemsApiEndPoint = (itemId = '') => {
//   return `${API_ENDPOINT_PRODUCT}${itemId ? `/${itemId}` : ''}`;
// };



//export const transactionsEndpoint = ()
