import React from "react";
import { getJourneyDate } from "../../HelperFunctions/helper";

export default function Header({ date, hideUpdatedOn }) {
  return (
    <div className="container">
      <div className="row align-items-center justify-flex-start pl-3 grayBGColor">
        {hideUpdatedOn ? ('') :
          (<div className="col-lg-3 p-0 m-0 col-sm-3 whiteText text-left journey-header search-journey-header collected-header">
            Updated on:
          </div>)}
        <div className={hideUpdatedOn ? "col-lg-12 p-0 m-0 col-sm-12 text-left search-journey-header journey-date whiteText" : "search-journey-header col-lg-7 p-0 m-0  col-sm-7 text-left journey-date whiteText"}>
          <span> {date}</span>
        </div>
      </div>
    </div>
  );
}
