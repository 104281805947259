import React, { useEffect } from 'react'
import { useHistory, useLocation, } from 'react-router-dom'

export default function RootPage() {
    const history = useHistory();
    useEffect(() => {
        history.push('/search');
    }, [])
    return (
        <div>

        </div>
    )
}
