import React, { useState, useEffect } from "react";
import LogoHeader from "../../components/CommonHeader/LogoHeader";
import SearchText from "../../components/SearchPage/SearchText";
import {
  getDateFromEPOC,
  getFormatDate,
  getJourneyDate,
  getJourneyDateFromEpoch,
  getJourneyTime, getPerfectEPOC,
  getQueryParams,
} from "../../HelperFunctions/helper";
import { search } from "../../redux/actions/tableActions";
import "../../assets/style/common.scss";
import store from "../../redux/reducers/store";
import Collected from "../../components/TrackingDetails/Collected";
import Journey from "../../components/JourneyDetails/Journey";
import expand from "../../assets/images/expand.png";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import { LowPriority, SentimentSatisfied } from "@material-ui/icons";

import Loader from "../../components/Loader/Loader";
import { useHistory } from "react-router-dom";


export default function TrackHome({ location }) {
  
  const urlhistory=useHistory()
  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingDetails, settrackingDetails] = useState([]);
  const [hideHistory, sethideHistory] = useState(false);
  const [showError, setError] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [first_half, setFirstHalf] = useState([]);
  const [second_half, setSecondHalf] = useState([]);
  const [isDelivered, setIsDelivered] = useState(false);


  useEffect(() => {
    // alert(location.search)
    if (location.search) {
      let queryParams = getQueryParams(location.search.slice(1));
      setTrackingNumber(queryParams.tracking);

      if (queryParams.tracking) {
        fetchData(queryParams.tracking);
      }
    }
  }, [location]);

  const fetchData = (trackno) => {
    setLoader(true)
    store.dispatch(search(trackno)).then(async (val) => {

      let resdata = [];
      if (val.status) {
      setLoader(false)

        resdata = val.data.tracking_details;
        setIsDelivered(val.data.delivered ? true : false);
        if (resdata.length) {
          settrackingDetails([...resdata]);
          setError(false);
          divideArray([...resdata]);

          if (val.data && val.data.length <= 1) {
            sethideHistory(true);
          }
        }
      } else {
        setTrackingNumber(undefined);
        setError(true);
        setLoader(false)
        // if(!showLoader){
        urlhistory.push("/search")

        // }
      }
      // setLoader(false)

    });

  };

 const divideArray=(copyTrackDetails)=>{
   // console.log('original',copyTrackDetails)
   let fh = [], sh = [];
   for (let ii in copyTrackDetails.reverse())
   {
     (ii <= 2) ? fh.push(copyTrackDetails[ii]) : sh.push(copyTrackDetails[ii]);

   }
   setFirstHalf([...fh])
   // console.log('first_half', fh)
   // console.log('second_half', sh);
   setSecondHalf([...sh])
 }
  //   trackingDetails.length
  if (document.getElementById('google_translate_placeit') !== null) {
    const translateElement = document.getElementById('google_translate_element');
    if (translateElement) {
      document.getElementById('google_translate_placeit').appendChild(translateElement);
    }
  }

  const filterCollected = (trackingDetails) => {
    let collected = trackingDetails.filter((val) => {
      return val.comment === "delivered" || val.comment === "Delivered";
    });

    if (collected.length) {
      return collected[0];
    }

    return trackingDetails[trackingDetails.length - 1];
  };


  useEffect(()=>{
    // console.log(document.location.search.toString().length,'len')
    /*if(document.location.search.toString().length>0)
    {

      setTimeout(function(){
        window.googleTranslateElementInit()
      }, 2000);
    }*/

  },[trackingNumber])
  return (

    <div className="container">
      <script type="text/javascript" src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit">
      </script>
      {/* <Graph/> */}
      {showLoader ? <Loader /> : <>
        {!trackingNumber?(
          <div className="trackhome-block w-100">
            <LogoHeader />

            <div className="d-flex justify-content-center">
              <div className="col-lg-8 col-md-9 col-sm-10">
                <SearchText
                  showOldLink={true}
                  showError={showError}
                  trackDetails={trackingDetails}
                />
              </div>
            </div>
          </div>
        ) : (
            ""
          )}
        {trackingNumber && trackingDetails.length ? (
          <div className="justify-content-center mt-2 mb-5 p-3 ">
            <div className="flex-column col-sm-12 col-lg-12">
              <LogoHeader />
              <div className="align-self-center">
                <React.Fragment>
                  <div className="row justify-content-center pt-5">
                    <div className="col-lg-4 col-md-8 col-sm-8 mr-lg-5 ">
                      <SearchText
                        showError={showError}
                        trackDetails={trackingDetails}
                      />

                      <div className="text-left pt-2">
                        <div>
                          <span>
                          ALL DELIVERIES ARE DONE BY APPOINTMENT ONLY. "ETA TO DESTINATION CITY STATUS" DOES NOT CONSTITUTE A DELIVERY APPOINTMENT DATE TO CUSTOMER.
                            </span>
                        </div>

                        <div className="pt-2">
                          <span >
                          IF YOU HAVE NOT RECEIVED A CALL FROM THE DELIVERY AGENT TO CONFIRM A DATE YOUR ORDER HAS NOT YET BEEN SCHEDULED FOR HOME DELIVERY.
                            </span>
                        </div>

                        <div className="pt-2">
                          <span >
                          Note: - Someone 18 or older must be able to sign for the delivery. Please read and complete the delivery survey on the bill of lading prior to signing for your delivery.
                            </span>
                        </div>
                        <div id="google_translate_placeit" className="mt-4"></div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-8 col-md-8 p-0 m-0">
                      {trackingNumber && trackingDetails.length ? (
                        <div className="pt-3">
                          <div className="align-self-center collected-div p-3 ">
                            {/* {console.log(trackingDetails[trackingDetails.length-1])} */}
                            <Collected
                              trackingNumber={trackingNumber}
                              latestDetails = {filterCollected(trackingDetails)}
                              date={getJourneyDateFromEpoch(getPerfectEPOC(filterCollected(trackingDetails).start_date))}
                            />
                          </div>
                          {

                            first_half.length > 0 && !isDelivered
                              ?
                                (
                                    first_half.map((val, index) => {
                                      return (
                                      <div className="align-self-center pt-3 pb-2" key={index}>
                                        <Journey
                                            trackingDetails={{
                                              status: val.status,
                                              date: getJourneyDate(val.updated_at),
                                              formattedDate:  getDateFromEPOC(getPerfectEPOC(val.start_date)),
                                              time: getJourneyTime(val.updated_at),
                                              comment: val.comment,
                                              hideUpdatedOn: false,
                                            }}
                                        />
                                      </div>)
                                    })
                                )
                                : ('')
                          }


                          {/*{trackingDetails.length >= 1 ? (
                            <div className="align-self-center pt-3 pb-2">
                              <Journey
                                trackingDetails={{
                                  status: trackingDetails[trackingDetails.length - 1].status,
                                  date: getJourneyDate(getDateFromEPOC(
                                      getPerfectEPOC(trackingDetails[trackingDetails.length - 1].start_date))
                                  ),
                                  formattedDate: getDateFromEPOC(
                                      getPerfectEPOC(trackingDetails[trackingDetails.length - 1].start_date)),
                                  time: getJourneyTime(
                                    trackingDetails[trackingDetails.length - 1].created_at
                                  ),
                                  comment: trackingDetails[trackingDetails.length - 1].comment,
                                  hideUpdatedOn: false,
                                }}
                              />
                            </div>
                          ) : (
                              ""
                            )}*/}
                          {
                            !isDelivered ? 
                            <div className="pt-5">
                            {!hideHistory && second_half.length > 0 ? (
                              <div
                                className="float-left  pointer"
                                onClick={() => sethideHistory(true)}
                              >
                                <ArrowDropDownCircleIcon
                                  style={{ fontSize: "28px" }}
                                />{" "}
                                  Hide earlier journey
                              </div>
                            ) : second_half.length > 0 ? (
                              <div
                                className="float-left  pointer"
                                onClick={() => sethideHistory(false)}
                              >
                                <img
                                  src={expand}
                                  height="26px"
                                  width="26px"
                                ></img>{" "}
                                  Show earlier journey
                              </div>
                            ) : (
                                  <div></div>
                                )}

                            {second_half.length > 0 && !hideHistory
                              ? second_half.map((val, index) =>
                                <div
                                  className="align-self-center pt-5"
                                  key={index}
                                >
                                  <Journey
                                    trackingDetails={{
                                      status: val.status,
                                      date: getJourneyDate(val.updated_at),
                                      formattedDate:  getDateFromEPOC(getPerfectEPOC(val.start_date)),
                                      time: getJourneyTime(val.updated_at),
                                      comment: val.comment,
                                      hideUpdatedOn: true,
                                    }}
                                  />
                                </div>
                              ) : ""}
                          </div> : ''
                          }
                        </div>
                      ) : (
                          // <div>
                          //   No Result Found <SentimentDissatisfiedRoundedIcon />
                          // </div>
                          ""
                        )}
                    </div>
                  </div>
                </React.Fragment>
               
                {/* )} */}
                {/* <React.Fragment>
              {trackingNumber && trackingDetails.length ? "" : ""}
            </React.Fragment> */}
              </div>
            </div>
          </div>
        ) : (
            ''
          )}

      </>}
     

    </div>
  );
}
