import React from "react";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {getJourneyDateFromEpoch ,getJourneyDate, getPerfectEPOC, titleCase} from '../../HelperFunctions/helper'

export default function Collected({
  trackingNumber,
  date = "",
  latestDetails
}) {
  // const [location,setLocation]=uset
  return (
    <div className="container-content">

      { (latestDetails.comment === 'delivered' || latestDetails.comment === 'Delivered')
          ?
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="float-left d-flex collected-header">
                <CheckCircleIcon className="" style={{ color: "green" }} />
                <div className="ml-3 pl-2">
                  <b>{titleCase(latestDetails.comment)}</b>
                </div>
              </div>
            </div>
            <div className="ml-5">
              <div className="col-lg-12 col-sm-12 text-left pt-3">
                <h6 className="card-text journey-date">
                  Tracking Number:<b> {trackingNumber}</b>
                </h6>
              </div>
              <div className="col-lg-12 col-sm-12 text-left">
                <p className="card-text pb-2 journey-date">
                  Your item was {latestDetails.status === "waiting_to_ship" ? "waiting to ship" : latestDetails.status} on:{" "}
                  <strong>
                    {date}
                  </strong>
                </p>
              </div>
            </div>
          </div>
          :
          <div className="row">
            <div className="ml-5">
              <div className="col-lg-12 col-sm-12 text-left pb-1">
                <h6 className="card-text journey-date">
                  Tracking Number:<b> {trackingNumber}</b>
                </h6>
              </div>
              <div className="col-lg-12 col-sm-12 text-left">
                <p className="card-text mb-0 journey-date collected-com">
                  {latestDetails.comment}
                </p>
                <strong className="small"> {getJourneyDateFromEpoch(getPerfectEPOC(latestDetails.start_date))}</strong>
              </div>
            </div>
          </div>

      }



    </div>
  );
}
