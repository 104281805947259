import React, { useState } from "react";
import "../../assets/style/common.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Redirect, useHistory } from "react-router-dom";
import { useStore } from "react-redux";
import { userLogin } from "../../redux/actions/userActions";
import { connect } from "react-redux";
import { setUser } from "../../redux/actions/userActions";


const Login = ({ redirectLocation, ...props }) => {
  const urlhistory = useHistory();
  const user = useStore();


  const [userCreds, setUserCreds] = useState();

  const SigninSchema = Yup.object().shape({
    password: Yup.string()
      .min(5, "Too Short!(Minimum 5 characters")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const redirectToForgotPassword = () => {
    urlhistory.push("/forgot");
  };
  const redirectToSignup = () => {
    urlhistory.push("/register");
  };
  const redirectToLocation = () => {

    //urlhistory.goBack()
    if (redirectLocation !== '/login') {

      urlhistory.push(redirectLocation);
    } else {
      urlhistory.push('/home');

    }
  };

  if (props.user && props.user.user && props.user.user.token) {
      return <Redirect to="/home" />
  }

  return (
    <div className="container">
      <div className="d-flex justify-content-center h-100">
        <div className="card w-60">
          <div className="card-header">
            <h3>Sign In</h3>
          </div>
          <div className="card-body">
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={SigninSchema}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                setUserCreds(values);
                props.userLogin(values).then((resp) => {
                  if (resp) {
                    setSubmitting(false);
                    redirectToLocation();
                  }
                }).catch(err => {
                  setErrors({password: err})
                })
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="d-flex justify-content-start">
                    <label className="form-label-text">Email:</label>
                  </div>
                  <Field
                    className="form-control"
                    placeholder="Enter your email"
                    type="email"
                    name="email"
                  />
                  <ErrorMessage
                    className="error"
                    name="email"
                    component="div"
                    style={{ marginTop: 5 }}
                  />
                  <div className="d-flex justify-content-start" style={{ width: "100%", marginTop: 45 }}>
                    <label className="form-label-text">Password:</label>
                  </div>
                  <Field
                    className="form-control"
                    placeholder="Enter your password"
                    type="password"
                    name="password"
                  />
                  <ErrorMessage
                    className="error"
                    name="password"
                    component="div"
                    style={{ marginTop: 5 }}
                  />
                  <div className="d-flex justify-content-center" style={{ width: "100%" }}>
                    <button
                      style={{ marginTop: 30 }}
                      className={
                        "submit-button-padding small-screen noOutline btn p-3 btn-blue-shadow btn-primary btn-lg rounded-pill submit-btn-font popup-submit-btn "
                      }
                      type="submit"
                    >
                      Sign In
              </button>
                  </div>


                </Form>
              )}
            </Formik>
          </div>

        </div>
      </div>
    </div>
  );
};

const mapStateToProps = storeState => {
  return {
    user: storeState.user
  }
}


export default connect(mapStateToProps, { setUser, userLogin })(Login);

// <div className="card-footer">
// <div className="d-flex justify-content-center">
//   Don't have an account?{" "}
//   <a href="javascript:void(0)" onClick={redirectToSignup}>
//     Sign Up
//   </a>
// </div>
// <div className="d-flex justify-content-center">
//   <a href="javascript:void(0)" onClick={redirectToForgotPassword}>
//     Forgot your password?
//   </a>
// </div>
// </div>