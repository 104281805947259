import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function Loader() {
    return (
        <div className="trackhome-block">
             <Spinner  animation="border" variant="primary" />
        </div>
    )
}
