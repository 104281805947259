
  import {API_ENDPOINT_ADD_TRACKER} from "../../services/apiEndpoints";
import { fetchData } from "./apiRequest";
  
  export const addNewTrackDetail = (details) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("POST", API_ENDPOINT_ADD_TRACKER, details))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  };

  export const getTrackDetails=(trackNo)=>{
    return (dispatch) => {
        return new Promise((resolve, reject) => {
          dispatch(fetchData("GET", 'trackers/'+trackNo, null))
            .then((resp) => {
              resolve(resp.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      };
  }

  export const editTrackDetail = (details,id) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("put", `trackers/${id}`, details))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  };

  export const importTrackers = (data) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("POST", `trackers/import`, data))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  }

  export const uploadFile=(id,data)=>{
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("POST", `trackers/${id}/pod`, data))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  }

  export const deleteFile=(id)=>{
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("DELETE", `trackers/${id}/pod`, ''))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  }

  export const deleteTracker = (id) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("delete", `trackers/${id}`, null))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  };

  export const deleteTrackingDetails = (trackerId, detailId) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchData("delete", `trackers/${trackerId}/tracking_details/${detailId}` ))
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  }
