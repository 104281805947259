export function getQueryParams(query) {
  let params = query.split("&");
  let queryParamObj = {};
  params.forEach((element) => {
    let param = element.split("=");
    queryParamObj[param[0]] = param[1];
  });
  return queryParamObj;
}

export const getDateFromEPOC = (epoc) => {
  let d = new Date(Number(epoc));
  let dd = d.getDate();
  let mm = d.getMonth() + 1;
  let yyyy = d.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  let today = yyyy + "-" + mm + "-" + dd;

  return today;
};

export const getPerfectEPOC = (epoc) => {
    return (epoc.length === 13) ? epoc : (epoc * 1000);
}

export const getJourneyDate = (date) => {
  let d = new Date(date);
  let splitDate = d.toString().split(" ");

  return (
    splitDate[0] +
    ", " +
    splitDate[1] +
    " " +
    splitDate[2] +
    ", " +
    splitDate[3]
  );
};

export const getFormatDate = (date) => {
  let result = date;
  return result.slice(0, 10);
};

export const getJourneyTime2 = (date) => {
  let d = new Date(date);
  let time = d.toTimeString().slice(0, 5);
  return time;
};

export const getJourneyTime = (d) => {
    let date = new Date(d);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const getJourneyDateFromEpoch = (epoch) => {
  // console.log(epoch)
  let d = new Date(Number(epoch));
  let splitDate = d.toString().split(" ");

  return (
    splitDate[0] +
    ", " +
    splitDate[1] +
    " " +
    splitDate[2] +
    ", " +
    splitDate[3]
  );
};

export function getDateToEPOC(epoc) {
  var today = new Date(epoc);
  // alert(today)

  var dd = today.getDate();

  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;
  return today;
}

export function titleCase(str) {

   return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  
}
